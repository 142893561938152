import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import '../styles/WithdrawModal.css';

const WithdrawModal = ({ isOpen, onClose }) => {
    const { totalTokenStaked, totalTokenStakedWithoutK, remainingDays, account, stakingContract, usdtDecimal } = useContext(WalletContext);
    const [amount, setAmount] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    const handleTransactionStart = () => {
        setIsLoading(true);
        console.log(`Transaction started..`);
    };

    const handleTransactionEnd = (transactionHash) => {
        if (transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`Transaction confirmed!`, explorerLink);
            setShowAlert(true);
        }
        setIsLoading(false);
        console.log(`Transaction end..`);
    };

    const handleMaxClick = async () => {
        const _depositedToken = await stakingContract.methods.depositedTokens(account).call();
        const depositedTokenString = Number(_depositedToken) / (10 ** usdtDecimal);
        setAmount(depositedTokenString.toFixed(2));
        setWithdrawAmount((depositedTokenString * (10 ** usdtDecimal)) - (3334 * (10 ** usdtDecimal)).toFixed(0));
    };

    const handleInput = (amount) => {
        setAmount(amount);
        setWithdrawAmount(0);
    }

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = account ? `https://enter.kasuapp.com/index.html?referral=${account}` : '';
    const shortAddress = shortenWalletAddress(account);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const handleSubmit = async () => {
        if (totalTokenStakedWithoutK < 41675) {
            showCustomAlert('Minimum amount to withdraw is 41675 KASU!');
            return;
        }
        let unstakeAmount = 0;
        if (withdrawAmount == 0) {
            const _unstakeAmount = (amount * (10 ** usdtDecimal)) - (3334 * (10 ** usdtDecimal));
            unstakeAmount = _unstakeAmount.toFixed(0);
        } else {
            unstakeAmount = withdrawAmount;
        }
        if (unstakeAmount > 0) {
            try {
                console.log("Going to withdraw stake amount..");
                handleTransactionStart();
                const txHash = await stakingContract.withdraw(unstakeAmount).send({ from: account });
                handleTransactionEnd(txHash.transactionHash);
            } catch (error) {
                console.log('Unstake amount', unstakeAmount);
                console.error("Error withdrow stakin amount", error);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Staking-Earning Wallet<span className="close-btn" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <span>Tokens</span>
                        <span>{totalTokenStaked} KASU</span>
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => handleInput(e.target.value)}
                        />
                        <button onClick={handleMaxClick} className="max-btn">Max</button>
                    </div>
                    <button onClick={handleSubmit} className="daily-withdraw-btn">Withdraw</button>
                    <div className="days-remaining">Days Remaining: {remainingDays}</div>
                    <p className="withdraw-info">Min withdraw is 41675 KASU. You can withdraw in multiples of 41675 KASU</p>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WithdrawModal;
