import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import '../styles/WithdrawCommition.css';

const WithdrawCommition = ({ isOpen, onClose }) => {
    const { isConnected, account, usdtDecimal, stakingContract } = useContext(WalletContext);
    const [amount, setAmount] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [commition, setCommition] = useState(0);

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    const handleTransactionStart = () => {
        setIsLoading(true);
        console.log(`Transaction started..`);
    };

    const handleTransactionEnd = (transactionHash) => {
        if (transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`Transaction confirmed!`, explorerLink);
            setShowAlert(true);
        }
        setIsLoading(false);
        console.log(`Transaction end..`);
    };

    const updateCommition = async () => {
        if (!isConnected) {
            return;
        }
        try {
            const userCommition = await stakingContract.methods.commition(account).call();
            const commitionNumber = Number(userCommition);
            const commitionInDecimal = commitionNumber / (10 ** usdtDecimal);
            if (commitionInDecimal > 0) {
                setCommition(commitionInDecimal.toFixed(2));
            }
            console.log('Commition updated.', commitionInDecimal.toFixed(2));
        } catch (error) {
            console.error('Error updating commition', error);
        }
    }

    const handleMaxClick = async () => {
        if (!stakingContract) {
            setAmount(0);
            return;
        }
        try {
            console.log('Going to set withdraw amount.');
            setAmount(commition);
        } catch (error) {
            console.error('Error to update pendong reward', error);
        }
    };

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = account ? `https://enter.kasuapp.com/index.html?referral=${account}` : '';
    const shortAddress = shortenWalletAddress(account);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const withdrawCommition = async () => {
        if (amount < 1667) {
            console.log("Commition Reward", amount);
            showCustomAlert('Wrong amount to claim!');
            return;
        }
        let commitionToWithdraw = 0;
        if (commitionToWithdraw == 0 && amount > 0) {
            commitionToWithdraw = (amount * (10 ** usdtDecimal)).toFixed(0);
        }
        try {
            if (commitionToWithdraw > 0) {
                handleTransactionStart();
                const txHash = await stakingContract.methods.withdrawCommition(commitionToWithdraw).send({ from: account });
                console.log(`Withdraw commition completed.`, txHash);
                handleTransactionEnd(txHash.transactionHash);
                updateCommition();
            }
        } catch (error) {
            console.error("Commission withdraw failed:", error);
            handleTransactionEnd();
            return;
        }
        setAmount(0);
        console.log('Commition withdraw Amount', commitionToWithdraw);
    };

    useEffect(() => {
        if (!isConnected) {
            return;
        }
        const intervalId = setInterval(() => {
            updateCommition();
        }, 1000);
        return () => clearInterval(intervalId);
    }, [isConnected, updateCommition]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Withdraw Referral Reward<span className="close-btn" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <span>Referral Reward</span>
                        <span>{commition} KASU</span>
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <button onClick={handleMaxClick} className="max-btn">Max</button>
                    </div>
                    <button onClick={withdrawCommition} className="daily-deposit-btn">Withdraw</button>
                    <p className="deposit-info">Min withdraw is 16670 KASU. You can withdraw in multiples of 16670 KASU. Withdraw fee 3334 KASU</p>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WithdrawCommition;
