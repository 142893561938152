import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import PendingRewardModul from './PendingRewardModul';
import WithdrawCommition from './WithdrawCommition';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import '../styles/DailyEarningModul.css';

const DailyEarningModul = ({ isOpen, onClose }) => {
    const { pendingReward, account, stakingContract, isConnected, usdtDecimal, contract } = useContext(WalletContext);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [explorerLink, setExplorerLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPendingRewardOpen, setIsPendingRewardOpen] = useState(false);
    const [isErningModulOpen, setIsErningModulOpen] = useState(false);
    const [commition, setCommition] = useState(0);
    const [commitionWithOutK, setCommitionWithOutK] = useState(0);

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    const handleTransactionStart = () => {
        setIsLoading(true);
        console.log(`Transaction started..`);
    };

    const handleTransactionEnd = (transactionHash, network) => {
        if (transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`Transaction confirmed!`, explorerLink);
        }
        setIsLoading(false);
        console.log(`Transaction end..`);
    };

    const updateCommition = async () => {
        if (!isConnected) {
            return;
        }
        try {
            const userCommition = await stakingContract.methods.commition(account).call();
            const commitionAmount = Number(userCommition) / (10 ** usdtDecimal);
            setCommitionWithOutK(commitionAmount);
            if (commitionAmount > 0 && commitionAmount < 1e3) {
                setCommition(commitionAmount.toFixed(2));
            } else if (commitionAmount >= 1e3 && commitionAmount < 1e6) {
                const commitionInKilo = commitionAmount / 1e3;
                setCommition(`${commitionInKilo.toFixed(2)} K`);
            } else if (commitionAmount >= 1e6 && commitionAmount < 1e9) {
                const commitionInKilo = commitionAmount / 1e6;
                setCommition(`${commitionInKilo.toFixed(2)} M`);
            } else if (commitionAmount >= 1e9) {
                const commitionInKilo = commitionAmount / 1e9;
                setCommition(`${commitionInKilo.toFixed(2)} B`);
            } else {
                setCommition(0);
            }
            console.log('Commition updated.', commitionAmount);
        } catch (error) {
            console.error('Error updating commition', error);
        }
    }

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const referralURL = account ? `https://enter.kasuapp.com/index.html?referral=${account}` : '';
    const shortAddress = shortenWalletAddress(account);

    const copyLink = () => {
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const reinvestPendingReward = async () => {
        if (pendingReward == 0) {
            console.log('No pending reward to reinvest.');
            showCustomAlert('No pending reward to reinvest.');
            return;
        }
        try {
            handleTransactionStart();
            const txHash = await stakingContract.methods.reinvestPendingReward().send({ from: account });
            console.log(`Reinvested pending reward completed.`, txHash);
            handleTransactionEnd(txHash.transactionHash, "Eth");
        } catch (error) {
            console.error('Error to reinvest pending reward', error);
            handleTransactionEnd();
        }
    };

    const reinvestReferralReward = async () => {
        if (commition == 0) {
            console.log('No commition reward to reinvest.');
            showCustomAlert('No commition reward to reinvest.');
            return;
        }
        try {
            handleTransactionStart();
            const txHash = await stakingContract.methods.reinvestCommition().send({ from: account });
            console.log(`Reinvested referral reward completed.`, txHash);
            handleTransactionEnd(txHash.transactionHash, "Eth");
        } catch (error) {
            console.error('Error to reinvest commition reward', error);
            handleTransactionEnd();
        }
    };

    const claimPendingReward = () => {
        if (pendingReward < 16670) {
            showCustomAlert('Minimum amount to claim is 16670 KASU!');
            return;
        } else {
            setIsPendingRewardOpen(true);
        }
    };

    const withdrawCommitionReward = () => {
        // console.log('Commition Amount:', commitionWithOutK);
        if (commitionWithOutK < 16670) {
            showCustomAlert('Minimum amount to withdraw is 16670 KASU!');
            return;
        } else {
            setIsErningModulOpen(true);
        }
    };

    useEffect(() => {
        if (!isConnected) {
          return;
        }
        const intervalId = setInterval(() => {
            updateCommition();
        }, 1000);
        return () => clearInterval(intervalId);
      }, [updateCommition]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Investment-Earning Wallet<span className="close-btn" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span className='referral-link'>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <div className='pending-reward'>Pending Rewards: <span className='reward'>{pendingReward}</span> KASU</div>
                        <div className='withdraw-claim-btn'>
                            <button onClick={claimPendingReward} className="claim-btn">Claim</button>
                            <button onClick={reinvestPendingReward} className="reinvest-btn">Reinvest</button>
                        </div>
                    </div>
                    <div className="tokens">
                        <div className='pending-reward'>Referral: <span className='reward'>{commition}</span> KASU</div>
                        <div className='withdraw-claim-btn'>
                            <button onClick={withdrawCommitionReward} className="claim-btn">Withdraw</button>
                            <button onClick={reinvestReferralReward} className="reinvest-btn">Reinvest</button>
                        </div>
                    </div>
                    <p className="minimum-withdraw">Min withdraw is 16670 KASU. You can withdraw in multiples of 16670 KASU</p>
                </div>
                <PendingRewardModul
                    isOpen={isPendingRewardOpen}
                    onClose={() => setIsPendingRewardOpen(false)}
                />
                <WithdrawCommition
                    isOpen={isErningModulOpen}
                    onClose={() => setIsErningModulOpen(false)}
                />
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DailyEarningModul;
