import React, { useState, useEffect, useRef } from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({ onItemClick }) => {
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const handleItemClick = (item) => {
    if (item === 'Home') {
      window.location.href = 'https://enter.kasuapp.com';
    } else if (item === 'Support') {
      window.location.href = 'https://t.me/kasuapp';
    } else {
      setSelectedItem(item);
      onItemClick(item);
      setIsOpen(false);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      console.log('Clicked outside sidebar');
      setIsOpen(false);
    } else {
      console.log('Clicked inside sidebar');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sidebarItems = [
    { name: 'Home', icon: 'fa fa-home' },
    { name: 'Dashboard', icon: 'fas fa-tachometer-alt' },
    { name: 'LevelEarnings', icon: 'fas fa-award' },
    { name: 'Referral', icon: 'fas fa-user-plus' },
    { name: 'History', icon: 'fas fa-history' },
    { name: 'Document', icon: 'fa fa-file' },
    { name: 'Support', icon: 'fa fa-headset' },
    { name: 'Tutorials', icon: 'fas fa-video' },
    { name: 'Become A Merchant', icon: 'fas fa-store' },
    { name: 'Ads & Promotion', icon: 'fas fa-ad' },
    { name: 'Tasks & Airdrop', icon: 'fas fa-tasks' }
  ];

  return (
    <>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </button>
      <div ref={sidebarRef} className={`sidebar-container ${isOpen ? '' : 'closed'}`}>
        <div className="sidebar">
          <div className="sidebar-content">
            {sidebarItems.map((item) => (
              <div
                key={item.name}
                className={`sidebar-item ${selectedItem === item.name ? 'active' : ''}`}
                onClick={() => handleItemClick(item.name)}
              >
                <i className={item.icon}></i>
                <span className="item-name">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
