import React, { useContext, useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import Web3 from 'web3';
import MerchantLibraryABI from '../ABI/MerchantLibraryABI.json';
import { WalletContext } from '../WalletContext';
import '../styles/BennerAds.css';

const BennerAds = () => {
    const { merchantLibraryAddress, setMerchantLibraryContract, merchantLibraryContract } = useContext(WalletContext);
    const [ads, setAds] = useState([]);

    useEffect(() => {
        const initializeContract = async () => {
            try {
                const web3 = new Web3(new Web3.providers.HttpProvider('https://polygon-mainnet.infura.io/v3/cd4d91bd753b485fa91edd565201a509'));
                const merchantLibraryContractInstance = new web3.eth.Contract(MerchantLibraryABI, merchantLibraryAddress);
                setMerchantLibraryContract(merchantLibraryContractInstance);
                console.log('Merchant library address', merchantLibraryAddress);
            } catch (error) {
                console.error('Error initializing Web3:', error);
            }
        };

        initializeContract();
    }, [merchantLibraryAddress, setMerchantLibraryContract]);

    const fetchUrls = async () => {
        if (merchantLibraryContract) {
            try {
                const adsNonce = await merchantLibraryContract.methods.promotionNonce().call();
                const timeNow = await merchantLibraryContract.methods.getCurrentTime().call();

                const newAds = [];

                for (let i = 78692; i <= Number(adsNonce); i++) {
                    const data = await merchantLibraryContract.methods.getAdsData(i).call();
                    console.log('Ads nonce', Number(adsNonce), 'Data', data);
                    if (Number(data.startAt) <= Number(timeNow) && Number(data.endAt) >= Number(timeNow) && data.isRunning) {
                        newAds.push({
                            logo: data.imageUrl,
                            url: data.redirectLink
                        });
                    }
                }

                console.log('Fetched ads:', newAds);
                setAds(newAds);
            } catch (error) {
                console.error('Error fetching URLs:', error);
            }
        }
    };

    useEffect(() => {
        if (merchantLibraryContract) {
            fetchUrls();
        }
    }, [merchantLibraryContract]);

    return (
        <Marquee pauseOnHover={true} speed={45} gradientWidth={0}>
            {ads.map((ad, index) => (
                <div key={index} className="images-item">
                    <a href={ad.url} target="_blank" rel="noopener noreferrer">
                        <img src={ad.logo} className="images-logo" alt={`Banner ${index + 1}`} />
                    </a>
                </div>
            ))}
        </Marquee>
    );
};

export default BennerAds;