import React from 'react';
import DocumentPdf from '../assets/docs/docs.pdf';

const Document = () => {
  return (
    <div className="pdf-container">
      <embed 
        src={DocumentPdf} 
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
  );
};

export default Document;