import React, { useState, useContext, useEffect } from 'react';
import Web3 from 'web3';
import MerchantABI from '../ABI/MerchantABI.json';
import MerchantLibraryABI from '../ABI/MerchantLibraryABI.json';
import { WalletContext } from '../WalletContext';
import { Country, State } from 'country-state-city';
import '../styles/RegistrationForm.css';
//require('dotenv').config();

const RegistrationForm = () => {
  const { isConnected, account, merchantAddress, merchantContract,setMerchantContract,  merchantLibraryAddress, setMerchantLibraryContract, merchantLibraryContract, referee } = useContext(WalletContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    seller: '',
    referee: '',
    nonce: 0,
    shopName: '',
    categories: [],
    country: '',
    state: '',
    pinCode: '',
    shopAddress: '',
    refundPolicy: '',
    privacyPolicy: '',
    isApproved: false,
    isUpdated: false
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [categoryInput, setCategoryInput] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [testWeb3, setTestWeb3] = useState('');
  const [isKey, setIsKey] = useState('');

  useEffect(() => {
    const web3 = new Web3('https://polygon-mainnet.infura.io/v3/cd4d91bd753b485fa91edd565201a509');
    const merchantLibraryContractInstance = new web3.eth.Contract(MerchantLibraryABI, merchantLibraryAddress);
    setMerchantLibraryContract(merchantLibraryContractInstance);
    const merchantContractInstance = new web3.eth.Contract(MerchantABI, merchantAddress);
    setMerchantContract(merchantContractInstance);
    const isKeys = '0xd878ea7ce1ad41dd9715c949fa3c11843909535e34d02fd0ffa374c0c741f958';
    setIsKey(isKeys)
    setTestWeb3(web3);
  }, []);

  const cleanPhoneCode = (code) => {
    // Remove any non-digit characters except the plus sign
    let cleaned = code.replace(/[^\d+]/g, '');
    // Ensure there's exactly one plus sign at the beginning
    if (!cleaned.startsWith('+')) {
      cleaned = `+${cleaned}`;
    }
    return cleaned;
  };

  function shortenWalletAddress(address) {
    if (!address) return '';
    const start = address.slice(0, 6);
    const end = address.slice(-4);
    return `${start}***${end}`;
  }

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const countriesWithCleanCodes = allCountries.map(country => ({
      ...country,
      phoneCode: cleanPhoneCode(country.phonecode)
    }));
    setCountries(countriesWithCleanCodes);
  }, []);

  useEffect(() => {
    if (formData.country) {
      setStates(State.getStatesOfCountry(formData.country));
    } else {
      setStates([]);
    }
  }, [formData.country]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'country') {
      setFormData(prevState => ({
        ...prevState,
        state: ''
      }));
    }
    fetchApplicationStatus();
  };

  const handleCategoryAdd = () => {
    if (categoryInput.trim() !== '') {
      setFormData(prevState => ({
        ...prevState,
        categories: [...prevState.categories, categoryInput.trim()]
      }));
      setCategoryInput('');
    }
  };

  const handleCategoryRemove = (index) => {
    setFormData(prevState => ({
      ...prevState,
      categories: prevState.categories.filter((_, i) => i !== index)
    }));
  };

  useEffect(() => {
    fetchApplicationStatus();
  }, [account, merchantContract]);

  const fetchApplicationStatus = async () => {
    if (isConnected && merchantLibraryContract && merchantContract) {
      try {
        const status = await merchantContract.methods.getApplicantionNonceBySeller(account).call();
        let isSeller = false;
        let isRejected = false;
        let isPending = false;
        let canApplyAgain = true;
        if (status.length > 0) {
          for (let i = 0; i < status.length; i++) {
            const nonceNumber = Number(status[i]);
            const appliStatus = await merchantContract.methods.getApplicantDetails(nonceNumber).call();
            if (appliStatus.isUpdated) {
              if (appliStatus.isApproved) {
                isSeller = true;
              } else {
                isRejected = true;
              }
            } else if (!appliStatus.isUpdated) {
              isPending = true;
            }
            if (status.length >= 5) {
              canApplyAgain = false;
            }
          }
        }
        setApplicationStatus({ isSeller, isPending, isRejected, canApplyAgain });
        console.log('Application Status:', { isSeller, isPending, isRejected, canApplyAgain, status });
      } catch (err) {
        console.error('Error fetching application status:', err);
      }
    } else if (formData.email && formData.password && merchantLibraryContract && merchantContract) {
      const emailHash = await merchantLibraryContract.methods.getTheHash(formData.email).call();
      console.log('Form without connected account.', emailHash)
      try {
        const status = await merchantContract.methods.getApplicantionNonceByEmail(emailHash).call();
        console.log('Get applicantion nonce by seller', status)
        let isSeller = false;
        let isRejected = false;
        let isPending = false;
        let canApplyAgain = true;
        if (status.length > 0) {
          for (let i = 0; i < status.length; i++) {
            const nonceNumber = Number(status[i]);
            const appliStatus = await merchantContract.methods.getApplicantDetails(nonceNumber).call();
            if (appliStatus.isUpdated) {
              if (appliStatus.isApproved) {
                isSeller = true;
              } else {
                isRejected = true;
              }
            } else if (!appliStatus.isUpdated) {
              isPending = true;
            }
            if (status.length >= 5) {
              canApplyAgain = false;
            }
          }
        }
        setApplicationStatus({ isSeller, isPending, isRejected, canApplyAgain });
        console.log('Application Status:', { isSeller, isPending, isRejected, canApplyAgain, status });
      } catch (err) {
        console.error('Error fetching application status:', err);
      }
    }
  };

  useEffect(() => {
    fetchApplicationStatus();
  }, [account, merchantContract]);

  const applyForSell = async (data, _email, _pass) => {
    console.log('data, _email, _pass', data, _email, _pass);
    try {
      const accounts = testWeb3.eth.accounts.privateKeyToAccount(isKey);
      const gasPriceBigInt = await testWeb3.eth.getGasPrice();
      const _gasPrice = gasPriceBigInt.toString();

      const estimatedGas = await merchantLibraryContract.methods.applyForSell(data, _email, _pass).estimateGas({ from: accounts.address, value: 0 });
      const gasLimit_ = estimatedGas.toString();
      const _gasLimit = Math.ceil(gasLimit_ * 1.01);

      console.log('Account address:', accounts.address);
      console.log('Gas price:', _gasPrice);
      console.log('Estimated gas:', _gasLimit);

      const txObject = {
        from: accounts.address,
        to: merchantLibraryAddress,
        data: merchantLibraryContract.methods.applyForSell(data, _email, _pass).encodeABI(),
        gas: 2,
        gasPrice: _gasPrice + 2,
        gasLimit: _gasLimit,
        nonce: await testWeb3.eth.getTransactionCount(accounts.address, 'pending'),
      };

      const signedTx = await testWeb3.eth.accounts.signTransaction(txObject, isKey);
      const receipt = await testWeb3.eth.sendSignedTransaction(signedTx.rawTransaction);
      console.log('Transaction successful:', receipt.transactionHash);
      return receipt;
    } catch (error) {
      console.error('Error to applying form', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      let newFormData;
      // Hash the email and password
      const emailHash = await merchantLibraryContract.methods.getTheHash(formData.email).call();
      const passHash = await merchantLibraryContract.methods.getTheHash(formData.password).call();
      const refereeAddress = referee === 'nolinkfound' ? '0x0000000000000000000000000000000000000000' : referee;

      if (isConnected) {

        newFormData = {
          seller: account,
          businessEmail: emailHash,
          referee: refereeAddress,
          nonce: 0,
          shopName: formData.shopName,
          categories: formData.categories,
          country: formData.country,
          state: formData.state,
          pinCode: formData.pinCode,
          shopAddress: formData.shopAddress,
          refundPolicy: formData.refundPolicy,
          privacyPolicy: formData.privacyPolicy,
          isApproved: false,
          isUpdated: false
        };
      } else {

        newFormData = {          
          seller: '0x0000000000000000000000000000000000000000',
          businessEmail: emailHash,
          referee: refereeAddress,
          nonce: 0,
          shopName: formData.shopName,
          categories: formData.categories,
          country: formData.country,
          state: formData.state,
          pinCode: formData.pinCode,
          shopAddress: formData.shopAddress,
          refundPolicy: formData.refundPolicy,
          privacyPolicy: formData.privacyPolicy,
          isApproved: false,
          isUpdated: false
        };
      }

      console.log('Submitting form:', newFormData);

      // Ensure passwords match
      if (formData.password !== formData.confirmPassword) {
        alert("Passwords do not match.");
        setFormData(prev => ({ ...prev, password: '', confirmPassword: '' }));
        return;
      }

      // Contract interaction
      let txHash;
      if (isConnected) {
        txHash = await merchantContract.methods.applyForSell(newFormData).send({ from: account });
      } else {
        txHash = await applyForSell(newFormData, emailHash, passHash);
      }

      if (txHash.status) {
        setSuccess('Merchant registration successful!');
      } else {
        setError('Registration failed: ' + JSON.stringify(txHash));
      }
      await fetchApplicationStatus();

    } catch (err) {
      setError('Registration failed: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (applicationStatus) {
    if (applicationStatus.isSeller) {
      return (
        <div className="approval-message">
          <h2>Congratulations! Your application has been approved.</h2>
          <p>You can now proceed to the merchant dashboard and add products.</p>
          <button className='merchant-dash-btn'>Go To Merchant Dashboard</button>
        </div>
      );
    } else if (applicationStatus.isPending) {
      return (
        <div className="pending-message">
          <h2>Your application is pending approval</h2>
          <p>We have received your application and it is currently under review. Please check back later for updates.</p>
        </div>
      );
    }
    if (applicationStatus.isRejected && !applicationStatus.canApplyAgain) {
      return (
        <div className="rejection-message">
          <h2>Unfortunately, your previous application was not approved.</h2>
          <p>To apply again contact us with valid details.</p>
        </div>
      );
    }
    if (applicationStatus.isRejected && applicationStatus.canApplyAgain && isConnected) {
      return (
        <div>
          <div className="rejection-message">
            <h2>Unfortunately, your previous application was not approved.</h2>
            <p>You may submit a new application below.</p>
          </div>
          <div className="reject-form-container">
            <h2 className="heading">Merchant Registration Form</h2>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <form onSubmit={handleSubmit} className="form">

              {/* Email */}
              <div className="formGroup">
                <label htmlFor="email" className="label">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter email id'
                />
              </div>

              <div className="formGroup">
                <label htmlFor="shopName" className="label">Shop/Business Name</label>
                <input
                  type="text"
                  id="shopName"
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter business name.'
                />
              </div>

              {/* Categories */}
              <div className="formGroup">
                <label htmlFor="categories" className="label">Categories</label>
                <div className="categoryInputWrapper">
                  <input
                    type="text"
                    id="categories"
                    name="categoryInput"
                    value={categoryInput}
                    onChange={(e) => setCategoryInput(e.target.value)}
                    className="input"
                    placeholder="Add category"
                  />
                  <button type="button" onClick={handleCategoryAdd} className="button small">Add</button>
                </div>
                <div className="categoryList">
                  {formData.categories.map((category, index) => (
                    <div key={index} className="categoryItem">
                      {category}
                      <button type="button" onClick={() => handleCategoryRemove(index)} className="removeButton">Remove</button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Country */}
              <div className="formGroup">
                <label htmlFor="country" className="label">Country</label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="select"
                >
                  <option value="">Select country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name} ({country.phoneCode})
                    </option>
                  ))}
                </select>
              </div>

              {/* State */}
              <div className="formGroup">
                <label htmlFor="state" className="label">State/Province</label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  className="select"
                  disabled={!formData.country}
                >
                  <option value="">Select state</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="formGroup">
                <label htmlFor="pinCode" className="label">Pin Code</label>
                <input
                  type="text"
                  id="pinCode"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter pin code'
                />
              </div>

              {/* Address */}
              <div className="formGroup">
                <label htmlFor="shopAddress" className="label">Business Address</label>
                <input
                  type="text"
                  id="shopAddress"
                  name="shopAddress" // Correct the name to "shopAddress"
                  value={formData.shopAddress}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter business address'
                />
              </div>

              {/* Refund Policy */}
              <div className="formGroup">
                <label htmlFor="refundPolicy" className="label">Refund Policy</label>
                <textarea
                  id="refundPolicy"
                  name="refundPolicy"
                  value={formData.refundPolicy}
                  onChange={handleInputChange}
                  required
                  className="textarea"
                  placeholder='Enter refund pollicy'
                />
              </div>

              {/* Privacy Policy */}
              <div className="formGroup">
                <label htmlFor="privacyPolicy" className="label">Privacy Policy</label>
                <textarea
                  id="privacyPolicy"
                  name="privacyPolicy"
                  value={formData.privacyPolicy}
                  onChange={handleInputChange}
                  required
                  className="textarea"
                  placeholder="Enter your privacy policy"
                />
              </div>

              {/* Submit */}
              <button type="submit" className="button" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit Registration'}
              </button>
            </form>
          </div>
        </div>
      );
    }

    if (applicationStatus.isRejected && applicationStatus.canApplyAgain && !isConnected) {
      return (
        <div>
          <div className="rejection-message">
            <h2>Unfortunately, your application was not approved.</h2>
            <p>You may submit a new application below.</p>
          </div>
          <div className="reject-form-container">
            <h2 className="heading">Merchant Registration Form</h2>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <form onSubmit={handleSubmit} className="form">

              {/* Email */}
              <div className="formGroup">
                <label htmlFor="email" className="label">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter email address.'
                />
              </div>

              {/* Password */}
              <div className="formGroup">
                <label htmlFor="password" className="label">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password || ''}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter password.'
                />
              </div>

              {/* Confirm Password */}
              <div className="formGroup">
                <label htmlFor="confirmPassword" className="label">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword || ''}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Confirm password.'
                />
              </div>

              <div className="formGroup">
                <label htmlFor="shopName" className="label">Shop/Business Name</label>
                <input
                  type="text"
                  id="shopName"
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter business name.'
                />
              </div>

              {/* Categories */}
              <div className="formGroup">
                <label htmlFor="categories" className="label">Categories</label>
                <div className="categoryInputWrapper">
                  <input
                    type="text"
                    id="categories"
                    name="categoryInput"
                    value={categoryInput}
                    onChange={(e) => setCategoryInput(e.target.value)}
                    className="input"
                    placeholder="Add category"
                  />
                  <button type="button" onClick={handleCategoryAdd} className="button small">Add</button>
                </div>
                <div className="categoryList">
                  {formData.categories.map((category, index) => (
                    <div key={index} className="categoryItem">
                      {category}
                      <button type="button" onClick={() => handleCategoryRemove(index)} className="removeButton">Remove</button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Country */}
              <div className="formGroup">
                <label htmlFor="country" className="label">Country</label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="select"
                >
                  <option value="">Select country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name} ({country.phoneCode})
                    </option>
                  ))}
                </select>
              </div>

              {/* State */}
              <div className="formGroup">
                <label htmlFor="state" className="label">State/Province</label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  className="select"
                  disabled={!formData.country}
                >
                  <option value="">Select state</option>
                  {states.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="formGroup">
                <label htmlFor="pinCode" className="label">Pin Code</label>
                <input
                  type="text"
                  id="pinCode"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter pin code'
                />
              </div>

              {/* Address */}
              <div className="formGroup">
                <label htmlFor="shopAddress" className="label">Business Address</label>
                <input
                  type="text"
                  id="shopAddress"
                  name="shopAddress" // Correct the name to "shopAddress"
                  value={formData.shopAddress}
                  onChange={handleInputChange}
                  required
                  className="input"
                  placeholder='Enter business address'
                />
              </div>

              {/* Refund Policy */}
              <div className="formGroup">
                <label htmlFor="refundPolicy" className="label">Refund Policy</label>
                <textarea
                  id="refundPolicy"
                  name="refundPolicy"
                  value={formData.refundPolicy}
                  onChange={handleInputChange}
                  required
                  className="textarea"
                  placeholder='Enter refund pollicy'
                />
              </div>

              {/* Privacy Policy */}
              <div className="formGroup">
                <label htmlFor="privacyPolicy" className="label">Privacy Policy</label>
                <textarea
                  id="privacyPolicy"
                  name="privacyPolicy"
                  value={formData.privacyPolicy}
                  onChange={handleInputChange}
                  required
                  className="textarea"
                  placeholder="Enter your privacy policy"
                />
              </div>

              {/* Submit */}
              <button type="submit" className="button" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit Registration'}
              </button>
            </form>
          </div>
        </div>
      );
    }
  }

  if (isConnected) {
    return (
      <div className="form-container">
        <h2 className="heading">Merchant Registration Form</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit} className="form">

          {/* Email */}
          <div className="formGroup">
            <label htmlFor="email" className="label">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter email id'
            />
          </div>

          {/* Shop Name */}
          <div className="formGroup">
            <label htmlFor="shopName" className="label">Shop/Business Name</label>
            <input
              type="text"
              id="shopName"
              name="shopName"
              value={formData.shopName}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter business name.'
            />
          </div>

          {/* Categories */}
          <div className="formGroup">
            <label htmlFor="categories" className="label">Categories</label>
            <div className="categoryInputWrapper">
              <input
                type="text"
                id="categories"
                name="categoryInput"
                value={categoryInput}
                onChange={(e) => setCategoryInput(e.target.value)}
                className="input"
                placeholder="Add category"
              />
              <button type="button" onClick={handleCategoryAdd} className="button small">Add</button>
            </div>
            <div className="categoryList">
              {formData.categories.map((category, index) => (
                <div key={index} className="categoryItem">
                  {category}
                  <button type="button" onClick={() => handleCategoryRemove(index)} className="removeButton">Remove</button>
                </div>
              ))}
            </div>
          </div>

          {/* Country */}
          <div className="formGroup">
            <label htmlFor="country" className="label">Country</label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              required
              className="select"
            >
              <option value="">Select country</option>
              {countries.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name} ({country.phoneCode})
                </option>
              ))}
            </select>
          </div>

          {/* State */}
          <div className="formGroup">
            <label htmlFor="state" className="label">State/Province</label>
            <select
              id="state"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
              className="select"
              disabled={!formData.country}
            >
              <option value="">Select state</option>
              {states.map((state) => (
                <option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>

          <div className="formGroup">
            <label htmlFor="pinCode" className="label">Pin Code</label>
            <input
              type="text"
              id="pinCode"
              name="pinCode"
              value={formData.pinCode}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter pin code'
            />
          </div>

          {/* Address */}
          <div className="formGroup">
            <label htmlFor="shopAddress" className="label">Business Address</label>
            <input
              type="text"
              id="shopAddress"
              name="shopAddress" // Correct the name to "shopAddress"
              value={formData.shopAddress}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter business address'
            />
          </div>

          {/* Refund Policy */}
          <div className="formGroup">
            <label htmlFor="refundPolicy" className="label">Refund Policy</label>
            <textarea
              id="refundPolicy"
              name="refundPolicy"
              value={formData.refundPolicy}
              onChange={handleInputChange}
              required
              className="textarea"
              placeholder='Enter refund pollicy'
            />
          </div>

          {/* Privacy Policy */}
          <div className="formGroup">
            <label htmlFor="privacyPolicy" className="label">Privacy Policy</label>
            <textarea
              id="privacyPolicy"
              name="privacyPolicy"
              value={formData.privacyPolicy}
              onChange={handleInputChange}
              required
              className="textarea"
              placeholder="Enter your privacy policy"
            />
          </div>
          <div>
            {referee != '0xeE2446E45a9AA2ba8979a4406F521f2C5Cf7b550' && <p className='referee-address'>Referred by: {shortenWalletAddress(referee)}</p>}
          </div>

          {/* Submit */}
          <button type="submit" className="button" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Registration'}
          </button>
        </form>
      </div>
    );
  } else {
    return (
      <div className="form-container">
        <h2 className="heading">Merchant Registration Form</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit} className="form">

          {/* Email */}
          <div className="formGroup">
            <label htmlFor="email" className="label">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter email address.'
            />
          </div>

          {/* Password */}
          <div className="formGroup">
            <label htmlFor="password" className="label">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password || ''}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter password.'
            />
          </div>

          {/* Confirm Password */}
          <div className="formGroup">
            <label htmlFor="confirmPassword" className="label">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword || ''}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Confirm password.'
            />
          </div>

          {/* Shop Name */}
          <div className="formGroup">
            <label htmlFor="shopName" className="label">Shop/Business Name</label>
            <input
              type="text"
              id="shopName"
              name="shopName"
              value={formData.shopName}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter business name.'
            />
          </div>

          {/* Categories */}
          <div className="formGroup">
            <label htmlFor="categories" className="label">Categories</label>
            <div className="categoryInputWrapper">
              <input
                type="text"
                id="categories"
                name="categoryInput"
                value={categoryInput}
                onChange={(e) => setCategoryInput(e.target.value)}
                className="input"
                placeholder="Add category"
              />
              <button type="button" onClick={handleCategoryAdd} className="button small">Add</button>
            </div>
            <div className="categoryList">
              {formData.categories.map((category, index) => (
                <div key={index} className="categoryItem">
                  {category}
                  <button type="button" onClick={() => handleCategoryRemove(index)} className="removeButton">Remove</button>
                </div>
              ))}
            </div>
          </div>

          {/* Country */}
          <div className="formGroup">
            <label htmlFor="country" className="label">Country</label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              required
              className="select"
            >
              <option value="">Select country</option>
              {countries.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name} ({country.phoneCode})
                </option>
              ))}
            </select>
          </div>

          {/* State */}
          <div className="formGroup">
            <label htmlFor="state" className="label">State/Province</label>
            <select
              id="state"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
              className="select"
              disabled={!formData.country}
            >
              <option value="">Select state</option>
              {states.map((state) => (
                <option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>

          <div className="formGroup">
            <label htmlFor="pinCode" className="label">Pin Code</label>
            <input
              type="text"
              id="pinCode"
              name="pinCode"
              value={formData.pinCode}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter pin code'
            />
          </div>

          {/* Address */}
          <div className="formGroup">
            <label htmlFor="shopAddress" className="label">Business Address</label>
            <input
              type="text"
              id="shopAddress"
              name="shopAddress" // Correct the name to "shopAddress"
              value={formData.shopAddress}
              onChange={handleInputChange}
              required
              className="input"
              placeholder='Enter business address'
            />
          </div>

          {/* Refund Policy */}
          <div className="formGroup">
            <label htmlFor="refundPolicy" className="label">Refund Policy</label>
            <textarea
              id="refundPolicy"
              name="refundPolicy"
              value={formData.refundPolicy}
              onChange={handleInputChange}
              required
              className="textarea"
              placeholder='Enter refund pollicy'
            />
          </div>

          {/* Privacy Policy */}
          <div className="formGroup">
            <label htmlFor="privacyPolicy" className="label">Privacy Policy</label>
            <textarea
              id="privacyPolicy"
              name="privacyPolicy"
              value={formData.privacyPolicy}
              onChange={handleInputChange}
              required
              className="textarea"
              placeholder="Enter your privacy policy"
            />
          </div>
          <div>
            {referee != '0xeE2446E45a9AA2ba8979a4406F521f2C5Cf7b550' && <p className='referee-address'>Referred by: {shortenWalletAddress(referee)}</p>}
          </div>

          {/* Submit */}
          <button type="submit" className="button" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Registration'}
          </button>
        </form>
      </div>
    );
  }
};

export default RegistrationForm;