// WalletContext.js
import { React, createContext, useState } from 'react';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [account, setAccount] = useState('');
  const [isConnected, setIsConnected] = useState('');
  const [web3, setWeb3] = useState('');
  const [isApproved, setApproved] = useState(false);
  const [contract, setContract] = useState('');
  const [stakingContract, setStakingContract] = useState('');
  const [usdtContract, setUsdtContract] = useState('');
  const [referee, setReferee] = useState('');
  const [milestonRewards, setMilestonRewards] = useState('');
  const [totalRefferals, setTotalRefferals] = useState('');
  const [totalEarning, setTotalEarning] = useState('');
  const [todaysEarning, setTodaysEarning] = useState('');
  const [thisMonthEarning, setThisMonthEarning] = useState('');
  const [thisYearEarning, setThisYearEarning] = useState('');
  const [itemSelected, setItemSelected] = useState('');
  const [dataLibraryAddress, setDataLibraryAddress] = useState('');
  const [masterAddress, setMasterAddress] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [dataLibraryContract, setDataLibraryContract] = useState('');
  const [stakingAddress, setStakingAddress] = useState('');
  const [remainingDays, setRemainingDays] = useState(0);
  const [totalTokenStaked, setTotalTokenStaked] = useState(0);
  const [totalTokenStakedWithoutK, setTotalTokenStakedWithoutK] = useState(0);
  const [totalInveste, setTotalInvest] = useState(0);
  const [referalIncome, setReferalIncome] = useState(0);
  const [pendingReward, setPendingReward] = useState(0);
  const [erninngData, setErninngData] = useState(0);
  const [userLavels, setUserLavels] = useState(0);
  const [joiningDate, setJoiningDate] = useState(0);
  const [usdtDecimal, setUsdtDecimal] = useState(0);
  const [isModulOpened, setIsModulOpened] = useState(false);
  const [merchantAddress, setMerchantAddress] = useState('');
  const [merchantLibraryAddress, setMerchantLibraryAddress] = useState('');
  const [merchantContract, setMerchantContract] = useState('');
  const [merchantLibraryContract, setMerchantLibraryContract] = useState('');
  const [businessReward, setBusinessReward] = useState(0);
  const [connectedChainId, setConnectedChainId] = useState('');

  return (
    <WalletContext.Provider value={{
      account, setAccount, referee, setReferee, isConnected, setIsConnected, totalTokenStakedWithoutK, setTotalTokenStakedWithoutK,
      web3, setWeb3, contract, setContract, stakingAddress, setStakingAddress,
      isApproved, setApproved, remainingDays, setRemainingDays, usdtDecimal, setUsdtDecimal,
      masterAddress, setMasterAddress, totalTokenStaked, setTotalTokenStaked,
      tokenAddress, setTokenAddress, totalInveste, setTotalInvest, isModulOpened, setIsModulOpened,
      stakingContract, setStakingContract, referalIncome, setReferalIncome, connectedChainId, setConnectedChainId,
      usdtContract, setUsdtContract, pendingReward, setPendingReward, merchantAddress, setMerchantAddress,
      milestonRewards, setMilestonRewards, erninngData, setErninngData, merchantContract, setMerchantContract,
      totalRefferals, setTotalRefferals, userLavels, setUserLavels, businessReward, setBusinessReward,
      thisMonthEarning, setThisMonthEarning, joiningDate, setJoiningDate, merchantLibraryContract, setMerchantLibraryContract,
      todaysEarning, setTodaysEarning, totalEarning, setTotalEarning, merchantLibraryAddress, setMerchantLibraryAddress,
      thisYearEarning, setThisYearEarning, itemSelected, setItemSelected,
      dataLibraryAddress, setDataLibraryAddress, dataLibraryContract, setDataLibraryContract
    }}>
      {children}
    </WalletContext.Provider>
  );
};