import React, { useState, useContext, useEffect } from 'react';
import MerchantForm from './MerchantForm';
import formVideo from '../assets/formvideo.mp4';

function RegistrationForm() {
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  useEffect(() => {
    const fetchVideoData = async () => {
      setShowVideoPopup(true);
    };

    fetchVideoData();
  }, []);

  const VideoPopup = ({ video, onClose }) => {
    return (
      <div className="video-popup-overlay">
        <div className="video-popup">
          <button className="popup-close-btn" onClick={onClose}>✕</button>
          <video src={formVideo} controls autoPlay width="100%">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showVideoPopup && (
        <VideoPopup
          video={formVideo}
          onClose={() => setShowVideoPopup(false)}
        />
      )}
      <MerchantForm />
    </div>
  );
}

export default RegistrationForm;