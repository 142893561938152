import React, { useState, useEffect, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import WithdrawModal from "../components/WithdrawModal";
import DailyEarningModul from "../components/DailyEarningModul";
import WithdrawBusinessReward from "../components/WithdrawBusinessReward";
import axios from 'axios';
import BtcLogo from "../assets/crypto/btc-icon.png";
import EthLogo from "../assets/crypto/eth-icon.png";
import BnbLogo from "../assets/crypto/bnb-icon.png";
import BtcCashLogo from "../assets/crypto/bitcoin-cash-icon.png";
import DogeLogo from "../assets/crypto/doge-icon.png";
import SolLogo from "../assets/crypto/solana-icon.webp";
import XrpLogo from "../assets/crypto/xrp-icon.png";
import CardanoLogo from "../assets/crypto/cardano-icon.png";
import KasuLogo from "../assets/crypto/kasu-icon.png";
import CustomAlert from './CustomAlert';
import '../styles/TokenEarnings.css';

const TokenEarnings = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDailyModalOpen, setDailyModalOpen] = useState(false);
  const [isBusinessModalOpen, setBusinessModalOpen] = useState(false);
  const { isConnected, totalTokenStakedWithoutK, remainingDays, businessReward } = useContext(WalletContext);
  const [tokenPrice, setTokenPrices] = useState({
    ETH: 2616.09,
    BNB: 522.27,
    BTC: 58388.11,
    BCH: 330.09,
    DOGE: 0.13,
    SOL: 145.33,
    XRP: 0.45,
    ADA: 0.32,
    KASU: 0.05
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const showCustomAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
  };

  const handleWithdrawModul = async () => {
    if (!isConnected) {
      showCustomAlert('Connect your wallet first.');
      return;
    }
    try {setModalOpen(true);
      // console.log('Calling withdraw modul.', totalTokenStakedWithoutK, remainingDays);
      // if (totalTokenStakedWithoutK > 0 && remainingDays == 0) {
      //   setModalOpen(true);
      // } else if (totalTokenStakedWithoutK === 0) {
      //   showCustomAlert('You don\'t have tokens to withdraw.');
      // } else if (remainingDays > 0) {
      //   showCustomAlert(`After ${remainingDays} days you will be able to withdraw.`);
      // }
    } catch (error) {
      console.error("Withdrawal module failed to open:", error);
    }
  };

  const handleDailyWithdraw = async () => {
    if (!isConnected) {
      showCustomAlert('Connect your wallet first.');
      return;
    }
    try {setDailyModalOpen(true);
      // if (totalTokenStakedWithoutK !== 0) {
      //   console.log("Total staked:", totalTokenStakedWithoutK);
      //   setDailyModalOpen(true);
      // } else if (totalTokenStakedWithoutK == 0) {
      //   showCustomAlert('You don\'t have reward to withdraw.');
      // }
    } catch (error) {
      console.error("Withdrawal module failed to open:", error);
    }
  };

  const handleBusinessWithdraw = async () => {
    if (!isConnected) {
      showCustomAlert('Connect your wallet first.');
      return;
    }
    try {setBusinessModalOpen(true);
      // if (businessReward !== 0) {
      //   console.log("Total reward:", businessReward);
      //   setBusinessModalOpen(true);
      // } else if (businessReward == 0) {
      //   showCustomAlert('You don\'t have reward to withdraw.');
      // }
    } catch (error) {
      console.error("Withdraw reward module failed to open:", error);
    }
  };

  async function getCurrentPrice(symbol) {
    try {
      const response = await axios.get(`https://api.binance.com/api/v3/ticker/price`, {
        params: { symbol },
      });
      return parseFloat(response.data.price);
    } catch (error) {
      console.error('Error fetching current price:', error);
      throw error;
    }
  }

  const fetchTokenPrices = async () => {
    try {
      const btcUsdPrice = await getCurrentPrice('BTCUSDT');
      const bnbUsdPrice = await getCurrentPrice('BNBUSDT');
      const ethUsdPrice = await getCurrentPrice('ETHUSDT');
      const xrpUsdPrice = await getCurrentPrice('XRPUSDT');
      const solUsdPrice = await getCurrentPrice('SOLUSDT');
      const dogeUsdPrice = await getCurrentPrice('DOGEUSDT');
      const bchUsdPrice = await getCurrentPrice('BCHUSDT');
      const adaUsdPrice = await getCurrentPrice('ADAUSDT');
      console.log('All Price', btcUsdPrice, bnbUsdPrice, xrpUsdPrice, ethUsdPrice, solUsdPrice, dogeUsdPrice, bchUsdPrice, adaUsdPrice);

      setTokenPrices({
        ETH: ethUsdPrice,
        BNB: bnbUsdPrice,
        BTC: btcUsdPrice,
        XRP: xrpUsdPrice,
        SOL: solUsdPrice,
        DOGE: dogeUsdPrice,
        BCH: bchUsdPrice,
        ADA: adaUsdPrice,
        KASU: 0.0006
      });
    } catch (error) {
      console.error('Error fetching token prices:', error);
    }
  };

  useEffect(() => {
    fetchTokenPrices();
    const intervalId = setInterval(fetchTokenPrices, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const cryptoData = [
    { name: 'KASU', price: `$ ${tokenPrice.KASU}`, logo: KasuLogo },
    { name: 'ETH', price: `$ ${tokenPrice.ETH}`, logo: EthLogo },
    { name: 'BNB', price: `$ ${tokenPrice.BNB}`, logo: BnbLogo },
    { name: 'BTC', price: `$ ${tokenPrice.BTC}`, logo: BtcLogo },
    { name: 'SOL', price: `$ ${tokenPrice.SOL}`, logo: SolLogo },
    { name: 'XRP', price: `$ ${tokenPrice.XRP}`, logo: XrpLogo },
    { name: 'DOGE', price: `$ ${tokenPrice.DOGE}`, logo: DogeLogo },
    { name: 'ADA', price: `$ ${tokenPrice.ADA}`, logo: CardanoLogo },
    { name: 'BCH', price: `$ ${tokenPrice.BCH}`, logo: BtcCashLogo }
  ];

  return (
    <div className="token-earnings-container">
      <h2 className="token-earnings-title">Token Earnings</h2>
      <div className="earnings-buttons">
        <div className="earnings-button" onClick={handleWithdrawModul}>
          <i className='fas fa-wallet'></i>
          <span className='inve-ern'>Staking Earning</span>
        </div>
        <div className="earnings-button" onClick={handleBusinessWithdraw}>
          <i className='fas fa-wallet'></i>
          <span className='daily-ern'>Business Rewards</span>
        </div>
        <div className="earnings-button" onClick={handleDailyWithdraw}>
          <i className='fas fa-wallet'></i>
          <span className='daily-ern'>Daily Earnings</span>
        </div>
      </div>
      <div className="crypto-ticker">
        <div className="ticker-content">
          {cryptoData.map((crypto, index) => (
            <div key={index} className="crypto-item">
              <img src={crypto.logo} alt={`${crypto.name} Logo`} className="crypto-logo" />
              <span className='crypto-name'>{crypto.name}</span> <span className='crypto-price'>{crypto.price}</span>
            </div>
          ))}
        </div>
      </div>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={closeCustomAlert}
        />
      )}
      <WithdrawModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <WithdrawBusinessReward
        isOpen={isBusinessModalOpen}
        onClose={() => setBusinessModalOpen(false)}
      />
      <DailyEarningModul
        isOpen={isDailyModalOpen}
        onClose={() => setDailyModalOpen(false)}
      />
    </div>
  );
};

export default TokenEarnings;
