import React, { useContext, useState } from 'react';
import { WalletContext } from '../WalletContext';
import InvestModul from '../components/InvestModul';
import '../styles/LevelEarnings.css';

const LevelEarnings = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { userLavels, referalIncome, isConnected } = useContext(WalletContext);

  const handleInvestModul = () => {
    if (!isConnected) {
      return;
    } else {
      setModalOpen(true);
    }
  }

  const LevelCard = ({ level, stars, earnings, icon }) => (
    <div className="level-card">
      {icon && <div className="icon">{icon}</div>}
      <div className="level-header">
        <span className='level'>Level</span>
        <span className="stars">{'⭐'.repeat(stars)} {level}</span>
      </div>
      <div className="level-header">
        <span className='erning'>Earnings</span>
        <span className="earnings-display">{earnings}</span>
      </div>
    </div>
  );

  const UserLevel = ({ level, commission, description, isUnlocked }) => (
    <div className="user-level">
      <div className="user-level-card">
        <div className={`commission ${isUnlocked ? 'unlocked' : ''}`}>
          {isUnlocked ? 'F Com.:' : 'Pri Com:'} {commission}
        </div>
        <div className={`level-icon ${isUnlocked ? 'unlocked' : ''}`}>{isUnlocked ? '' : '🔒'}</div>
      </div>
      <div className="level-number">LEVEL {level}</div>
      <div className="level-description">{description}</div>
      <div>
        {isUnlocked && <button className='invest-btn' onClick={handleInvestModul}>Stake More</button>}
      </div>
    </div>
  );

  const icons = [
    <span className="bronze-icon">🥇</span>,
    <span className="silver-icon">🥈</span>,
    <span className="gold-icon">🥉</span>,
    <span className="platinum-icon">🏅</span>,
    <span className="diamond-icon">💎</span>
  ];

  return (
    <div className="earn-container">
      <div className="section-title">Level Earnings</div>
      <div className="erning-cards-container">
        {[1, 2, 3, 4, 5].map((level) => (
          <LevelCard
            key={level}
            level={level}
            stars={level}
            earnings={level <= userLavels ? referalIncome : 0}
            icon={icons[level - 1]}
          />
        ))}
      </div>
      
      <div className="section-title">User Levels</div>
      <div className="user-levels">
        <UserLevel level={1} commission="10.00%" description="Introduce 1 person and achieve $100 in sales." isUnlocked={userLavels >= 1} />
        <UserLevel level={2} commission="5.00%" description="Introduce 2 people and achieve $200 in sales." isUnlocked={userLavels >= 2} />
        <UserLevel level={3} commission="3.00%" description="Introduce 3 people and achieve $300 in sales." isUnlocked={userLavels >= 3} />
        <UserLevel level={4} commission="2.00%" description="Introduce 4 people and achieve $400 in sales." isUnlocked={userLavels >= 4} />
        <UserLevel level={5} commission="1.00%" description="Introduce 5 people and achieve $500 in sales." isUnlocked={userLavels >= 5} />
      </div>
      <InvestModul
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        onInvest={handleInvestModul}
      />
    </div>
  );
};

export default LevelEarnings;