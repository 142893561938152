import React, { useContext, useState, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import '../styles/ProductPromotionForm.css';

const ProductPromotionForm = () => {
  const { isConnected, account, merchantLibraryContract, usdtDecimal, usdtContract, tokenAddress, merchantLibraryAddress } = useContext(WalletContext);
  const [formData, setFormData] = useState({
    productName: '',
    bannerImageUrl: '',
    promotionDays: '',
    redirectLink: '',
    promotionDuration: '',
    amount: 0
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [explorerLink, setExplorerLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setApproved] = useState(false);

  const showCustomAlert = (message, explorerLink = '') => {
    setAlertMessage(message);
    setExplorerLink(explorerLink);
    setShowAlert(true);
  };

  const closeCustomAlert = () => {
    setShowAlert(false);
  };

  const handleTransactionStart = () => {
    setIsLoading(true);
    console.log(`Transaction started..`);
  };

  const handleTransactionEnd = (transactionHash) => {
    if (transactionHash) {
      const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
      showCustomAlert(`Transaction confirmed!`, explorerLink);
      setShowAlert(true);
    }
    setIsLoading(false);
    console.log(`Transaction end..`);
  };

  const updateApproval = async () => {
    if (!isConnected && !usdtContract) {
      console.log('Not connected with wallet.');
      return;
    }
    try {
      const _allowance = await usdtContract.methods.allowance(account, merchantLibraryAddress).call();
      const allowance = Number(_allowance);
      let amountApproved = 0;
      if (allowance > 0) {
        amountApproved = allowance / (10 ** usdtDecimal)
      }
      console.log('Update approval product', allowance, 'Amount:', amountApproved);
      if (amountApproved > 0) {
        setApproved(true);
        getButtonText();
      }
      console.log('Approvel updated.');
    } catch (error) {
      console.error(`Error updating approve:`, error);
    }
  }

  const getButtonText = () => {
    if (isApproved) {
      return `Submit Now`;
    } else {
      return 'Approve';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Update the payment based on promotion duration
    if (name === 'promotionDuration') {
      let amount = 0;
      switch (value) {
        case '1 month':
          amount = 5;
          break;
        case '2 months':
          amount = 10;
          break;
        case '3 months':
          amount = 15;
          break;
        case '6 months':
          amount = 25;
          break;
        case '1 year':
          amount = 50;
          break;
        default:
          amount = 0;
      }
      setFormData(prevState => ({
        ...prevState,
        amount
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isConnected) {
      showCustomAlert('Wallet not connected.');
      return;
    }
    const _allowance = await usdtContract.methods.allowance(account, merchantLibraryAddress).call();
    let allowance = Number(_allowance);
    const amountInWei = (formData.amount) * (10 ** usdtDecimal);

    const adsData = {
      name: formData.productName,
      nonce: 0,
      imageUrl: formData.bannerImageUrl,
      duration: getPromotionDays(formData.promotionDuration),
      redirectLink: formData.redirectLink,
      amount: amountInWei,
      startAt: 0,
      endAt: 0,
      isRunning: false,
      isUpdate: false
    };
    console.log('Ads Data:', adsData);
    if (isApproved && allowance >= amountInWei) {
      try {
        handleTransactionStart();
        const txHash = await merchantLibraryContract.methods.adPromotion(adsData).send({ from: account });
        handleTransactionEnd(txHash.transactionHash);
      } catch (error) {
        console.error("Investmant failed:", error);
        handleTransactionEnd();
        return;
      }
    } else {
      await approveToken();
    }
  };

  const approveToken = async () => {
    // console.log('approveToken function', web3, contract);
    if (isConnected && usdtContract) {
      try {
        console.log('Approving usdt...');
        const amountToApprove = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
        const allowance = await usdtContract.methods.allowance(account, merchantLibraryAddress).call();
        const allowanceNumber = Number(allowance);
        const allowanceAmount = allowanceNumber / (10 ** usdtDecimal);
        const amountInWei = (formData.amount) * (10 ** usdtDecimal);
        console.log('Allowance', allowanceNumber);
        if (amountInWei == 0) {
          return;
        }
        if (allowanceAmount >= amountInWei) {
          setApproved(true);
          getButtonText();
        } else {
          handleTransactionStart();
          const txHash = await usdtContract.methods.approve(merchantLibraryAddress, amountToApprove).send({
            from: account
          });
          handleTransactionEnd(txHash.transactionHash);
          const _currentAllowance = await usdtContract.allowance(account, merchantLibraryAddress).call();
          const currentAllowance = Number(_currentAllowance);
          if (currentAllowance > 0) {
            setApproved(true);
            getButtonText();
            console.log(`${amountToApprove.toUpperCase()} approved successfully.`);
          } else {
            console.log(`Unable to approvel status.`);
          }
        }
      } catch (error) {
        console.error(`Error approving ${tokenAddress.toUpperCase()}:`, error);
        handleTransactionEnd();
      }
    } else {
      showCustomAlert('Please connect your wallet first.');
      console.log('Please connect your wallet first.');
    }
    handleTransactionEnd();
  };

  // Helper function to convert duration to days
  function getPromotionDays(duration) {
    switch (duration) {
      case '1 month': return 30;
      case '2 months': return 60;
      case '3 months': return 90;
      case '6 months': return 180;
      case '1 year': return 365;
      default: return 0;
    }
  }

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    const intervalId = setInterval(() => {
      updateApproval();
      getButtonText();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [usdtContract, isConnected, updateApproval, getButtonText]);

  return (
    <div className="product-promotion-form">
      <h2>Promote Your Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="productName">Product Name:</label>
          <input
            type="text"
            id="productName"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            placeholder="Enter product name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="bannerImageUrl">
            Banner Image URL: <span className="banner-size">Size must be 50px height and 120px width.</span>
          </label>
          <input
            type="url"
            id="bannerImageUrl"
            name="bannerImageUrl"
            value={formData.bannerImageUrl}
            onChange={handleChange}
            placeholder="Enter banner image URL"
            required
          />
        </div>

        {formData.bannerImageUrl && (
          <div className="banner-preview">
            <img
              src={formData.bannerImageUrl}
              alt="Product Banner"
              className="banner-image"
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="redirectLink">Redirect Link:</label>
          <input
            type="url"
            id="redirectLink"
            name="redirectLink"
            value={formData.redirectLink}
            onChange={handleChange}
            placeholder="Enter the link"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="promotionDuration">Promotion Duration:</label>
          <select
            id="promotionDuration"
            name="promotionDuration"
            value={formData.promotionDuration}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select duration</option>
            <option value="1 month">1 Month | $5</option>
            <option value="2 months">2 Months | $10</option>
            <option value="3 months">3 Months | $15</option>
            <option value="6 months">6 Months | $25</option>
            <option value="1 year">1 Year | $50</option>
          </select>
        </div>
        <button type="submit">{getButtonText()}</button>
      </form>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          explorerLink={explorerLink}
          onClose={closeCustomAlert}
        />
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <img src={loadingSpinner} alt="Loading..." />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPromotionForm;
