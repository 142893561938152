import React, { useEffect, useRef, useContext } from "react";
import { createChart, ColorType } from "lightweight-charts";
import { WalletContext } from '../WalletContext';
import "../styles/EarningsChart.css";

export default function Chart(props) {
  const { erninngData } = useContext(WalletContext);
  const {
    colors: {
      backgroundColor = "#23252d",
      lineColor = "#795DC7",
      textColor = "#FFFFFF",
      areaTopColor = "#5C80CF",
      areaBottomColor = "#140A22",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
      },
      grid: {
        horzLines: {
          color: "#140A22",
          visible: false,
        },
        vertLines: {
          color: "#1a4879",
        },
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: true,
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: "rgba(32, 38, 46, 0.1)",
          labelVisible: true,
        },
      },
      width: chartContainerRef.current.clientWidth,
      height: 300,
    });

    chart.timeScale().fitContent();

    const newSeries = chart.addAreaSeries({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
    });

    if (erninngData.length > 0) {
      newSeries.setData(erninngData);
    }

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
    erninngData
  ]);

  return (
    <div ref={chartContainerRef} className="chart" />
  );
}
