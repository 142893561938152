import React, { useContext, useEffect, useState } from 'react';
import { WalletContext } from '../WalletContext';
import Marquee from "react-fast-marquee";
import WithdrawModal from "../components/WithdrawModal";
import InvestModul from "../components/InvestModul";
import KasuLogo from "../assets/logo-kasu.png";
import CustomAlert from './CustomAlert';
import '../styles/EarningData.css';

const EarningData = () => {
  const { remainingDays, stakingContract, account,
    totalTokenStaked, totalTokenStakedWithoutK, totalInveste, referalIncome, isConnected, isModulOpened, setIsModulOpened } = useContext(WalletContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isInvestModalOpen, setInvestModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const showCustomAlert = (message) => {
      setAlertMessage(message);
      setShowAlert(true);
  };

  const closeCustomAlert = () => {
      setShowAlert(false);
  };

  const handleWithdraw = async (amount) => {
    try {
      await stakingContract.methods.withdraw(amount).send({ from: account });
      console.log(`Withdrawn ${amount} tokens`);
    } catch (error) {
      console.error("Withdrawal failed:", error);
    }
  };

  const handleWithdrawModul = async () => {
    if (!isConnected) {
      showCustomAlert('Connect your wallet first.');
      return;
    }
    try {
      if (totalTokenStakedWithoutK > 0 && remainingDays === 0) {
        setModalOpen(true);
      } else if (totalTokenStakedWithoutK === 0) {
        showCustomAlert('You don\'t have tokens to withdraw.');
      } else if (remainingDays > 0) {
        showCustomAlert(`After ${remainingDays} days you will be able to withdraw.`);
      }
    } catch (error) {
      console.error("Withdrawal module failed to open:", error);
    }
  };

  const handleInvestModul = () => {
    if (!isConnected) {
      showCustomAlert("Please sign into your TRON supported wallet and try again.");
      return;
    } else {
      setInvestModalOpen(true);
    }
  }

  const getButtonText = () => {
    if (totalInveste > 0) {
      return `Stake More USDT`;
    } else {
      return 'Stake USDT';
    }
  };

  const referralURL = account ? `https://enter.kasuapp.com/index.html?referral=${account}` : '';

  const copyLink = () => {
    if (referralURL) {
      navigator.clipboard.writeText(referralURL)
        .then(() => {
          console.log('Referral URL copied to clipboard');
          showCustomAlert('Referral URL copied!');
        })
        .catch((err) => {
          console.error('Failed to copy referral URL: ', err);
        });
    } else {
      console.error('No referral URL available');
    }
  };

  useEffect(() => {
    if (isConnected) {
      getButtonText();
    }
    if (isConnected && totalInveste == 0 && !isModulOpened) {
      setInvestModalOpen(true);
      setIsModulOpened(true);
    }
  }, [isConnected, getButtonText]);

  return (
    <div className="all-time-stats">
      <Marquee>
        <div className="flex gap-2 text-textColor items-center px-8 py-2">
          <span className=''>Kasu is a decentralized platform designed for educational and informational purposes only. Participation in the Kasu platform involves the use of cryptocurrency, which carries inherent risks, including the potential loss of funds due to market volatility, security breaches, or regulatory changes. Cryptocurrency markets are highly unpredictable, and users should exercise caution when engaging with digital assets.

            Kasu does not guarantee any specific financial outcomes or returns. Participants are encouraged to conduct their own research and consult with financial professionals before making any decisions related to cryptocurrency use or investment.

            Please note that users from the United States are not eligible to participate in the Kasu platform due to local regulations. Additionally, we advise all users to check the legal and regulatory framework regarding cryptocurrency in their country before participating in the platform. By using Kasu, you agree to comply with all applicable laws in your jurisdiction.</span>
        </div>
      </Marquee>
      <div className="stats-grid">
        <div className="stat-card">
          <div><img className='icon-logo' src={KasuLogo} alt="Logo" /></div>
          <div className="stat-info">
            <h3>Total Tokens</h3>
            <p className="stat-value">{totalTokenStaked} KASU</p>
            <button className='invest-btn-erning'><i className='fa fa-credit-card'></i> Stake KASU</button>
          </div>
        </div>
        <div className="stat-card">
          <div><span class="glyphicon invest-icon glyphicon-piggy-bank"></span></div>
          <div className="stat-info">
            <h3>My Usdt Stake</h3>
            <p className="stat-value">$ {totalInveste}</p>
            <button className='invest-btn-erning' onClick={handleInvestModul}><i className='fas fa-chart-line'></i> {getButtonText()}</button>
          </div>
        </div>
        <div className="stat-card">
          <span className='glyphicon refer-icon glyphicon-user'></span>
          <div className="stat-info">
            <h3>Referral Rewards</h3>
            <p className="stat-value">$ {referalIncome}</p>
            <button className='refer-btn' onClick={copyLink}><i class="fa fa-user-plus" aria-hidden="true"></i> Copy Referral</button>
          </div>
        </div>
        <div className="stat-card">
          <span className='glyphicon days-icon glyphicon-time'></span>
          <div className="stat-info">
            <h3>Days remaining</h3>
            {remainingDays > 0 && <p className="stat-value">{remainingDays} {(remainingDays > 1) && 'Days'} {remainingDays <= 1 && 'Day'}</p>} {(remainingDays == 0 && isConnected && totalTokenStakedWithoutK > 0) && <p className='unlocked'>Unlocked!</p>} {(remainingDays == 0 && !isConnected) && <p>Connect Wallet!</p>} {(remainingDays == 0 && isConnected && totalTokenStakedWithoutK == 0) && <p>No Invest Found!</p>}
            <button className='withdraw-btn' onClick={handleWithdrawModul}><i className='fa fa-lock'></i> Withdraw</button>
          </div>
        </div>
      </div>
      <WithdrawModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onWithdraw={handleWithdraw}
      />
      <InvestModul
        isOpen={isInvestModalOpen}
        onClose={() => setInvestModalOpen(false)}
      />
      {showAlert && (
          <CustomAlert
              message={alertMessage}
              onClose={closeCustomAlert}
          />
      )}
    </div>
  );
};

export default EarningData;