import React, { useState, useContext, useEffect } from 'react';
import { WalletContext } from '../WalletContext';
import ManLogo from '../assets/MaleLogo.png';
import CustomAlert from './CustomAlert';
import loadingSpinner from '../assets/loading-spinner.gif';
import '../styles/InvestModul.css';

const InvestModul = ({ isOpen, onClose }) => {
    const { web3, isApproved, tokenAddress, setApproved, isConnected, account, contract,
        totalTokenStakedWithoutK, usdtContract, masterAddress, usdtDecimal, referee } = useContext(WalletContext);
    const [amount, setAmount] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [usdtTokenBalance, setUsdtBalance] = useState(0);
    const [explorerLink, setExplorerLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const showCustomAlert = (message, explorerLink = '') => {
        setAlertMessage(message);
        setExplorerLink(explorerLink);
        setShowAlert(true);
    };

    const closeCustomAlert = () => {
        setShowAlert(false);
    };

    const handleMaxClick = () => {
        setAmount(usdtTokenBalance);
    };

    function shortenWalletAddress(address) {
        if (!address) return '';
        const start = address.slice(0, 6);
        const end = address.slice(-4);
        return `${start}***${end}`;
    }

    const usdtBalance = async () => {
        if (!isConnected && !usdtContract && !account) {
            return;
        }
        try {
            const tokenBalance = await usdtContract.methods.balanceOf(account).call();
            const balanceString = Number(tokenBalance);
            const balance = balanceString / (10 ** 6);
            if (balance > 0) {
                setUsdtBalance(balance.toFixed(2));
            } else {
                setUsdtBalance(0);
            }
        } catch (error) {
            console.error("Error updating usdt balance", error);
        }
    }

    const handleTransactionStart = () => {
        setIsLoading(true);
        console.log(`Transaction started..`);
    };

    const handleTransactionEnd = (text, transactionHash) => {
        if (text && transactionHash) {
            const explorerLink = `https://polygonscan.com/tx/${transactionHash}`;
            showCustomAlert(`${text}`, explorerLink);
            setShowAlert(true);
        } else if (text) {
            showCustomAlert(text);
            setShowAlert(true);
        }
        setIsLoading(false);
        console.log(`Transaction end..`);
    };

    const updateApproval = async () => {
        if (!isConnected && !usdtContract) {
            console.log('Not connected with wallet.');
            return;
        }
        try {
            const _allowance = await usdtContract.methods.allowance(account, masterAddress).call();
            const allowance = Number(_allowance);
            let amountApproved = 0;
            if (allowance > 25) {
                amountApproved = allowance / (10 ** usdtDecimal)
            }
            console.log('Update approval', allowance, 'Amount:', amountApproved);
            if (amountApproved > 0) {
                setApproved(true);
                getButtonText();
            }
            console.log('Approvel updated.');
        } catch (error) {
            console.error(`Error updating approve:`, error);
        }
    }

    const getButtonText = () => {
        if (isApproved) {
            return `Stake Now`;
        } else {
            return 'Approve';
        }
    };

    const approveToken = async () => {
        // console.log('approveToken function', web3, contract);
        if (isConnected && usdtContract) {
            try {
                console.log('Approving usdt...');
                const amountToApprove = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
                const allowance = await usdtContract.methods.allowance(account, masterAddress).call();
                const allowanceNumber = Number(allowance);
                const allowanceAmount = allowanceNumber / (10 ** usdtDecimal);
                console.log('Allowance', allowanceNumber);
                if (allowanceAmount >= amount) {
                    setApproved(true);
                    getButtonText();
                } else {
                    handleTransactionStart();
                    const txHash = await usdtContract.methods.approve(masterAddress, amountToApprove).send({
                        from: account
                    });
                
                    // Check if the transaction is successful
                    if (txHash.status) {  // Transaction confirmed on the network
                        handleTransactionEnd(`USDT token approve successful!`, txHash.transactionHash);  // End transaction loading
                        console.log(`Approved ${amount} USDT.`, txHash.transactionHash);
                    } else {  // Transaction failed
                        handleTransactionEnd(`USDT token approve failed!`, txHash.transactionHash);
                    }
                    const _currentAllowance = await usdtContract.allowance(account, masterAddress).call();
                    const currentAllowance = Number(_currentAllowance);
                    if (currentAllowance > 0) {
                        setApproved(true);
                        getButtonText();
                        console.log(`${amountToApprove.toUpperCase()} approved successfully.`);
                    } else {
                        console.log(`Unable to update approvel status.`);
                    }
                }
            } catch (error) {
                console.error(`Error approving ${tokenAddress.toUpperCase()}:`, error);
            }
        } else {
            showCustomAlert('Please connect your wallet first.');
            console.log('Please connect your wallet first.');
        }
    };

    const referralURL = account ? `https://enter.kasuapp.com/index.html?referral=${account}` : '';
    const shortAddress = shortenWalletAddress(account);

    const copyLink = () => {
        if (!account) {
            alert('Please connect your wallet first, then copy.');
        }
        if (referralURL) {
            navigator.clipboard.writeText(referralURL)
                .then(() => {
                    console.log('Referral URL copied to clipboard');
                    showCustomAlert('Referral URL copied!');
                })
                .catch((err) => {
                    console.error('Failed to copy referral URL: ', err);
                });
        } else {
            console.error('No referral URL available');
        }
    };

    const handleSubmit = async () => {
        if (referee === 'nolinkfound') {
            if (totalTokenStakedWithoutK == 0) {
                showCustomAlert('To join our platform, please contact the support team for a referral link. Without a valid referral, you won\'t be able to access the full features of our dApp.');
                return;
            }
        }
        const balance = await web3.eth.getBalance(account);
        const polBalance = (Number(balance) / (10 ** 18));

        if (amount < 25) {
            showCustomAlert('Minimum invest amount is $25!');
            setAmount('');
            return;
        } else if (usdtTokenBalance < 25) {
            showCustomAlert('You don\'t have $25 USDT on your wallet!');
            setAmount('');
            return;
        } else if (polBalance < 0.001) {            
            showCustomAlert('You don\'t have POL to pay gas fee!');
            setAmount('');
            return;
        }
        
        let investAmount = 0;
        if (investAmount == 0 && amount > 0) {
            investAmount = amount * (10 ** usdtDecimal);
        }
        
        const _allowance = await usdtContract.methods.allowance(account, masterAddress).call();
        let allowance = Number(_allowance);
        console.log('Is approved:', isApproved, 'Invest amount:', investAmount, 'Allowance:', allowance);
    
        if (isApproved && allowance >= investAmount) {
            try {
                handleTransactionStart();  // Transaction loading indication
                const txHash = await contract.methods.invest(referee, investAmount).send({ from: account });
                
                // Check if the transaction is successful
                if (txHash.status) {  // Transaction confirmed on the network
                    handleTransactionEnd(`Investment successful! You have invested ${amount} USDT.`, txHash.transactionHash);  // End transaction loading
                    console.log(`Invested ${amount} USDT.`, txHash.transactionHash);
                } else {  // Transaction failed
                    handleTransactionEnd(`Investment failed!`, txHash.transactionHash);
                }
            } catch (error) {
                console.error("Investment failed:", error);
                handleTransactionEnd(`Transaction failed due to an error. Please check on polygonscan.com for more details.`);
            }
            setAmount('');
            console.log('Invest Amount', investAmount);
        } else {
            await approveToken();  // Handle token approval if not approved
        }
    };    

    useEffect(() => {
        if (!isConnected) {
            return;
        }
        const intervalId = setInterval(() => {
            usdtBalance();
            updateApproval();
            getButtonText();
        }, 1000);

        return () => clearInterval(intervalId);
    }, [usdtContract, isConnected, usdtBalance, updateApproval]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Staking<span className="close-btn" onClick={onClose}>X</span></h2>
                <div className="wallet-info">
                    <img className="user-icon" src={ManLogo}></img>
                    <div className="ref-code">
                        <span>Ref link #</span>
                        <span>{shortAddress} <button onClick={copyLink} className='copy-btn'>Copy</button></span>
                    </div>
                    <div className="tokens">
                        <span>Tokens Balance</span>
                        <span>{usdtTokenBalance} USDT</span>
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                        <button onClick={handleMaxClick} className="max-btn">Max</button>
                    </div>
                    <button onClick={handleSubmit} className="daily-deposit-btn">{getButtonText()}</button>
                    <p className="deposit-info">Min deposit amount $25.</p>
                    <p className="gasfee-info">Note: You need a small amount of POL in your wallet to pay gas fee.</p>
                </div>
                {showAlert && (
                    <CustomAlert
                        message={alertMessage}
                        explorerLink={explorerLink}
                        onClose={closeCustomAlert}
                    />
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">
                            <img src={loadingSpinner} alt="Loading..." />
                            <h6>Confirm on wallet...</h6>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InvestModul;
