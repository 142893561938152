import React, { useState, useContext } from 'react';
import { WalletContext } from '../WalletContext';
import '../styles/SocialMediaTasks.css';

const tasks = [
  { id: 1, name: 'Facebook', description: 'Like and share our Facebook page', reward: 250, link: 'https://www.facebook.com/profile.php?id=61566619371641' },
  { id: 2, name: 'Twitter', description: 'Follow and retweet us on Twitter', reward: 250, link: 'https://x.com/kasuappx' },
  { id: 3, name: 'YouTube', description: 'Subscribe to our YouTube channel', reward: 250, link: 'https://www.youtube.com/@Kasuapp' },
  { id: 4, name: 'YouTube', description: 'Create a YouTube video and post on your YouTube channel', reward: 3200, link: '' },
];

const SocialMediaTasks = () => {
  const { account, isConnected, merchantLibraryContract } = useContext(WalletContext);
  const [taskUrls, setTaskUrls] = useState({});
  const [errors, setErrors] = useState({});
  const [successMessages, setSuccessMessages] = useState({});

  const handleInputChange = (taskId, value) => {
    setTaskUrls(prevUrls => ({
      ...prevUrls,
      [taskId]: value
    }));
  };

  const handleUrlSubmit = async (taskId) => {
    if (!isConnected) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [taskId]: 'Please connect your wallet first.'
      }));
      return;
    }

    const url = taskUrls[taskId];
    if (!url) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [taskId]: 'Please enter a URL before submitting.'
      }));
      return;
    }

    try {
      const task = {
        name: tasks.find(t => t.id === taskId).name,
        user: account,
        nonce: 0,
        link: url,
        amount: tasks.find(t => t.id === taskId).reward,
        approvedAt: 0,
        isApproved: false,
        isUpdate: false
      };

      const txHash = await merchantLibraryContract.methods.updateTask(task).send({ from: account });
      console.log('Task data:', task);

      if (txHash.status) {
        setSuccessMessages(prevMessages => ({
          ...prevMessages,
          [taskId]: 'Task submitted successfully!'
        }));
        setErrors(prevErrors => ({
          ...prevErrors,
          [taskId]: null
        }));
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          [taskId]: 'Failed to submit task. Please try again.'
        }));
      }
    } catch (error) {
      console.error('Error submitting task to smart contract:', error);
      setErrors(prevErrors => ({
        ...prevErrors,
        [taskId]: 'Failed to submit task. Please try again.'
      }));
    }
  };

  return (
    <div className="kasu-tasks">
      <h1 className="title">Earn KASU Tokens</h1>
      {tasks.map(task => (
        <div key={task.id} className="task-item">
          <div className="task-header">
            <span className="task-description">{task.description}</span>
            <span className="task-reward">{task.reward} KASU</span>
          </div>
          <div className="task-action">
            <a href={task.link} target="_blank" rel="noopener noreferrer" className="social-link">
              Go to {task.name} Task
            </a>
          </div>
          <div className="task-proof">
            <form onSubmit={(e) => { e.preventDefault(); handleUrlSubmit(task.id); }}>
              <input
                type="text"
                name="proofUrl"
                placeholder="Enter proof URL"
                value={taskUrls[task.id] || ''}
                onChange={(e) => handleInputChange(task.id, e.target.value)}
                required
              />
              <button type="submit" className='social-button'>Submit Task</button>
            </form>
          </div>
          {errors[task.id] && <div className="error-message">{errors[task.id]}</div>}
          {successMessages[task.id] && (
            <div className="saved-proof">
              <h4>{successMessages[task.id]}</h4>
              <a href={taskUrls[task.id]} target="_blank" rel="noopener noreferrer">
                View Submitted Link
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SocialMediaTasks;