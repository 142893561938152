// App.js
import { React, useState } from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './components/Dashbord';
import TransactionHistory from './components/TransactionHistory';
import LevelEarnings from './components/LevelEarnings';
import VideoTutorials from './components/Video';
import ReferralNode from './components/ReferralNode';
import SocialMediaTasks from './components/SocialMediaTasks';
import Document from './components/Document';
import RegistrationForm from './components/RegistrationForm';
import ProductPromotionForm from './components/ProductPromotionForm';
import { WalletProvider } from './WalletContext';
import './App.css';

function App() {
  const [selectedItem, setSelectedItem] = useState('Dashboard');

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <WalletProvider>
      <div className="app" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          <Sidebar onItemClick={handleItemClick} />
          <div className="main-content" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Header />
            <div style={{ flex: 1 }}>
              {selectedItem === 'Dashboard' && <Dashboard />}
              {selectedItem === 'LevelEarnings' && <LevelEarnings />}
              {selectedItem === 'Referral' && <ReferralNode />}
              {selectedItem === 'History' && <TransactionHistory />}
              {selectedItem === 'Document' && <Document />}
              {selectedItem === 'Tutorials' && <VideoTutorials />}
              {selectedItem === 'Become A Merchant' && <RegistrationForm />}
              {selectedItem === 'Ads & Promotion' && <ProductPromotionForm />}
              {selectedItem === 'Tasks & Airdrop' && <SocialMediaTasks />}
            </div>
          </div>
        </div>
      </div>
    </WalletProvider>
  );
};

export default App;