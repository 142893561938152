import React, { useContext, useEffect, useState, useCallback } from 'react';
import { WalletContext } from '../WalletContext';
import logo from '../assets/MaleLogo.png';
import '../styles/ReferralNode.css';

const ReferralNode = ({ address, level = 0, onExpand, expandedNodes, currentPath }) => {
  const [referrals, setReferrals] = useState([]);
  const [totalEarned, setTotalEarned] = useState(0);
  const { contract, dataLibraryAddress, joiningDate, setJoiningDate, account, usdtDecimal, stakingContract } = useContext(WalletContext);

  const expanded = expandedNodes.has(address);
  
  console.log('Address node', address);

  const updateData = useCallback(async () => {
    if (!dataLibraryAddress || !account) {
      console.log('StakingContract or account not available');
      return;
    }
    try {
    const getTaxsNumbers = await dataLibraryAddress.methods.getTaxNumbers(account).call();
    console.log('Tax Data', getTaxsNumbers);
    } catch (error) {
      console.error('Error to update data:', error);
    }
  }, [dataLibraryAddress, account]);

  useEffect(() => {
    updateData();
  }, [updateData()]);

  useEffect(() => {
    if (expanded) {
      fetchReferralData();
    }
  }, [expanded, address, contract]);

  const fetchReferralData = async () => {
    try {
      const referralAddresses = await contract.methods.getReferrals(address).call();
      const _totalEarned = await contract.methods.getTotalEarning(address).call();
      const _stakingTime = await stakingContract.methods.stakingTime(account).call();
      const stakingTime = Number(_stakingTime.toString());
      const date = new Date(stakingTime * 1000);
      const dateOfJoin = date.toLocaleString();
      console.log('Joining Date 78692',_stakingTime, dateOfJoin);
      const earnedTotal = _totalEarned.toString() / (10 ** usdtDecimal);
      setReferrals(referralAddresses);
      setTotalEarned(earnedTotal.toFixed(2));
    } catch (error) {
      console.error('Error fetching referral data:', error);
    }
  };

  const handleExpand = () => {
    onExpand(address);
  };

  const truncateAddress = (addr) => {
    if (typeof addr !== 'string') return 'Invalid Address';
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  const isInPath = currentPath.includes(address);

  return (
    <div className={`referral-node level-${level}`}>
      <div className="node-content" onClick={handleExpand}>
        <img src={logo} alt="Referee Logo" className="avatar" />
        <div className="node-details">
          <div className="address">{truncateAddress(address)}</div>
          <div>Total Earned: ${totalEarned}</div>
        </div>
      </div>
      {(expanded || isInPath) && referrals.length > 0 && (
        <div className="children">
          {referrals.map((referralAddress, index) => (
            <ReferralNode
              key={index}
              address={referralAddress}
              level={level + 1}
              onExpand={onExpand}
              expandedNodes={expandedNodes}
              currentPath={currentPath}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ReferralTree = () => {
  const { contract, account } = useContext(WalletContext);
  const [userReferrals, setUserReferrals] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState(new Set());
  const [currentPath, setCurrentPath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUserReferrals();
  }, [contract, account]);

  const fetchUserReferrals = async () => {
    try {
      setIsLoading(true);
      const refereeAddresses = await contract.methods.getReferrals(account).call();
      setUserReferrals(refereeAddresses);
    } catch (error) {
      console.error('Error fetching user referrals:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpand = (address) => {
    if (currentPath.length === 0) {
      setCurrentPath([address]);
      setExpandedNodes(new Set([address]));
    } else {
      setCurrentPath((prevPath) => [...prevPath, address]);
      setExpandedNodes((prevExpanded) => new Set([...prevExpanded, address]));
    }
  };

  const handleBack = () => {
    setCurrentPath([]);
    setExpandedNodes(new Set());
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (userReferrals.length === 0) {
    return (
      <div className="referral-video-container">
        <div className="youtube-video">
          <iframe
            className="video-frame"
            width="679"
            height="382"
            src="https://www.youtube.com/embed/E2-nmZABnVQ"
            title="How to get started with Kasu."
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      </div>
    );
  }

  return (
    <div className="referral-tree-container">
      {currentPath.length > 0 && (
        <button className="back-button" onClick={handleBack}>
          Back To Home
        </button>
      )}
      {userReferrals.map((address, index) => (
        <ReferralNode
          key={index}
          address={address}
          onExpand={handleExpand}
          expandedNodes={expandedNodes}
          currentPath={currentPath}
        />
      ))}
    </div>
  );
};

export default ReferralTree;