import React from 'react';
import '../styles/Video.css';

const App = () => {
  return (
    <div className="tutorial-video-container">
      <div className="tutorial-youtube-video">
        <iframe width="679" height="382" src="https://www.youtube.com/embed/E2-nmZABnVQ" title="How to get started with Kasu." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
  );
};

export default App;