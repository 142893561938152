// App.js
import React from 'react';
import EarningData from './EarningData';
import EarningsChart from './EarningsChart';
import TokenEarnings from './TokenEarnings';
import BennerAds from './BennerAds';
import '../styles/Dashboard.css';

function Dashboard() {
  return (
    <div className='dash'>
      <BennerAds />
      <EarningData />
      <EarningsChart />
      <TokenEarnings />
    </div>
  );
}

export default Dashboard;